<template>
  <div></div>
</template>

<script>
import axios from 'axios';
import serverpath from '@/application_config';

export default {
  data() {
    return {
      sessionId: '',
    };
  },
  mounted() {
    this.start_session();
  },
  methods: {
    start_session() {
      if (this.sessionId === '') {
        axios.get(`${serverpath}start-session`).then((response) => {
          window.sessionStorage.setItem('upload.Id', response.data);
          this.sessionId = window.sessionStorage.getItem('upload.Id');
        });
      }
    },
  },
};
</script>
