<template>
  <b-card class="kercard">
    <b-row>
      <b-col cols="12" class="p-2 d-flex justify-content-center">
        <img alt="?" class="kercard-image" :src="CardIconPath" />
      </b-col>
      <b-col cols="12" class="mt-4">
        <h4>{{ CardHeadline1 }} <br /> {{ CardHeadline2 }}</h4>
        <p class="text-center kercard-text mt-4">{{ CardText }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  props: {
    CardIconPath: String,
    CardHeadline1: String,
    CardHeadline2: String,
    CardText: String,
  },
};
</script>
<style scoped>
.kercard {
  min-height:16rem;
  background-color: #1A1B1B;
  color: #a3a3a3;
}
.kercard-text {
    height: 11rem;
    text-overflow:ellipsis;
    overflow: hidden;
    line-height:1.5rem;
  }
.kercard-image {
  height: 5rem;
}
h4 {
    font-weight: 700;
    color:white;
    line-height: 2rem;
}
p {
    line-height: 1.8rem;
  }
</style>
