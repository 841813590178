<template>
  <div class="home">
    <Intro />
    <Intro2 />
    <Offerings />
    <Contact />
    <Aboutus />
    <References />
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from '@/components/IntroHome.vue';
import Intro2 from '@/components/Intro2Home.vue';
import Offerings from '@/components/OfferingsHome.vue';
import Contact from '@/components/ContactHome.vue';
import Aboutus from '@/components/AboutusHome.vue';
import References from '@/components/ReferencesHome.vue';

export default {
  name: 'HomeView',
  components: {
    Intro,
    Intro2,
    Offerings,
    Contact,
    Aboutus,
    References,
  },
};
</script>
