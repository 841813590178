<template>
  <div class="AItraining">
    <Intro2Component
      :Intro2Img="require('../assets/img/EUAITrainingheader_grren.webp')"
      :Intro2Headline="'KI-Schulungspflicht ab Februar 2025 – Sind Ihre Mitarbeiter vorbereitet?'"
      :Intro2Text="`Die EU-KI-Verordnung (AI Act) verpflichtet Unternehmen dazu, ihre Mitarbeiter
      im sicheren Umgang mit Künstlicher Intelligenz (LLMs) zu schulen. Handeln Sie jetzt!`"
      :IntroLink="`https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=OJ:L_202401689#enc_1`"
      :IntroLinkText="`Hier geht es zur EU-KI-Verordnung`"
    >
    </Intro2Component>
    <Intro2Ai />
    <AiOfferingList />
    <OfferingsAI />
    <AiWithUs />
    <AITrainingCTA />
    <ContactHome class="mt-5" :redirectTo="'/ai-contact-success'"></ContactHome>
    <AIFAQ />
  </div>
</template>
<script>
import Intro2Component from '@/components/tools/Intro2Component.vue';
import Intro2Ai from '@/components/Intro2AI.vue';
import OfferingsAI from '@/components/OfferingsAI.vue';
import AiOfferingList from '@/components/tools/AIOfferingsList.vue';
import AiWithUs from '@/components/AIWithUs.vue';
import AITrainingCTA from '@/components/AITrainingCTA.vue';
import AIFAQ from '@/components/AIFAQ.vue';
import ContactHome from '@/components/ContactHome.vue';

export default {
  components: {
    Intro2Component,
    Intro2Ai,
    OfferingsAI,
    AiOfferingList,
    AiWithUs,
    AITrainingCTA,
    AIFAQ,
    ContactHome,
  },
};
</script>
<style></style>
