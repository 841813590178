<template>
  <div>
    <b-row>
        <b-col cols="2" class="col-xxl-2 col-xl-2"></b-col>
   <b-col cols="12" class="col-xxl-8 col-xl-8 px-2">
   <b-card class="kercard p-2 mb-5">
                  <h4><b>Unser Angebot</b></h4>
                  <ul class="text-left p-0 pt-3">
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Praxiserprobte Schulungen für Unternehmen jeder Größe
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Richtline für Ihr Untermehmen, um die Inhalte des AI Acts umzusetzen
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Individuell zugeschnitten auf Ihre Branche & KI-Anwendungsfälle
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Von Experten entwickelt, basierend auf den Anforderungen des AI Acts
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Zertifikat als Nachweis für die Schulungspflicht
                    </li>
                  </ul>
                </b-card>
</b-col>
</b-row>
</div>

  </template>
<script>
export default {
  components: {

  },
};
</script>
<style scoped>
.jobad-icon {
  margin-right: 0.5rem;
  color: #2b572e;
}
ul {
  list-style: none;
  padding-left: 0;
}

</style>
