<!--
JobadHeader
JobadTasks
JobaddBenefits
JobaddQuote
JobadApplication
-->
<template>
  <div class="webdev">
    <IntroComponent
      :IntroHeadline="'Werde Teil einer Entwicklerverbindung! '"
      :IntroBg="require('../assets/img/web_student_cut.png')"
      :IntroText="
        'Fachleute zu kennen, die Vertrauen verdient haben,' +
        ' ist wichtiger denn je. Durch das immense Wachstum von Wissen und Informationen' +
        ' ist es immer herausfordernder den Überblick zu behalten. Menschen, die bei schwierigen' +
        ' Fragen weiterhelfen können, kennt man am besten persönlich.'
      "
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <ItstudentStory />
    <JobadBenefits />
    <JobadQuote
      :quoteImage="require('../assets/img/tim_huebsch.png')"
      :quoteText="
        '„Nach dem Studium habe ich direkt Projektverantwortung bekommen.' +
        ' Ich habe im ersten Jahr eine super Lernkurve gehabt. Besonders weil ich, wenn ich Fragen' +
        ' hatte, immer eine ausführliche Erklärung bekommen habe.“'
      "
      :quoteName="'Tim'"
      :quoteDate="'seit 2020 im Team'"
    />
    <ApplicationItstudent />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import ItstudentStory from '@/components/tools/ItstudentStory.vue';
import JobadBenefits from '@/components/tools/JobadBenefits.vue';
import JobadQuote from '@/components/tools/JobadQuote.vue';
import ApplicationItstudent from '@/components/tools/ApplicationItstudent.vue';

export default {
  components: {
    IntroComponent,
    ItstudentStory,
    JobadBenefits,
    JobadQuote,
    ApplicationItstudent,
  },
  data() {
    return {
      tasks: [
        {
          task:
            'Je nach deinem persönlichen Schwerpunkt kannst du im gesamten' +
            ' Stack vom Frontend bis zum Backend entwickeln',
        },
        {
          task:
            'Nutzung und Weiterentwicklung deiner persönlichen technischen Expertise von' +
            ' Support bis hin zur kompletten Umsetzungsverantwortung',
        },
        { task: 'Beständiges up to date halten über technische Neuerungen in der Webentwicklung.' },
      ],
      profiles: [
        { profile: 'Grundlegende Programmierkenntnisse im modernen Webstack' },
        { profile: 'Begeisterung für technische Lösungen und eigenverantwortliches Arbeiten.' },
        { profile: 'Erste Erfahrungen mit Backendtechnologien' },
        { profile: 'Abgeschlossenes Studium im IT-Bereich oder einer fachlich passenden Ausbildung.' },
        { profile: 'Agiles Mindset sowie ein ausgeprägter Teamgeist' },
        { profile: 'Einwandfreie Deutsch- und Englischkenntnisse' },
      ],
    };
  },
};
</script>
