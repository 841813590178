<template>
  <div class="AItraining">
    <Intro2Component
      :Intro2Img="require('../assets/img/EUAITrainingheader_grren.webp')"
      :Intro2Headline="'Vielen Dank für die Kontaktanfrage'"
      :IntroLink="`https://keravonos.com/`"
      :IntroLinkText="`Zurück zur Startseite`"
    >
    </Intro2Component>
    <div class="my-5">
      <AIFAQ />
    </div>
  </div>
</template>
<script>
import Intro2Component from '@/components/tools/Intro2Component.vue';
import AIFAQ from '@/components/AIFAQ.vue';

export default {
  components: {
    Intro2Component,
    AIFAQ,
  },
};
</script>
<style></style>
