<!-- eslint-disable max-len -->
<template>
  <b-container fluid>
    <b-row class="intro2">
      <b-col
        cols="12"
        class="p-4 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center img-col"
      >
        <img alt="?" class="img-fluid" :src="Intro2Img" />
      </b-col>
      <b-col
        cols="12"
        class="col-md-6 col-lg-6 col-xl-5 d-flex justify-content-center align-items-center align-content-center flex-wrap justify-content-md-start"
      >
        <h1 class="intro2-headline mb-5 mt-5 text-md-left">
          {{ Intro2Headline }}
        </h1>
        <p class="mb-4">
          {{ Intro2Text }}
        </p>
        <b-col cols="12" class="p-0 mb-4">
          <a :href="IntroLink">{{ IntroLinkText }}</a>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.intro2 {
  text-align: left;
  line-height: 1.7rem;
  color: white;
  background-color: #0f0f0f;
}
.intro2-headline {
  text-align: left;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 0;
  border-radius: 0.25rem;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}
.bg-fullscreen-intro2 {
  display: none;
}
@media (max-width: 768px) {
  .intro2-headline {
    text-align: center;
  }
  h1 {
    font-size: 1.5rem;
  }
}
</style>

<script>
export default {
  props: {
    Intro2Img: String,
    Intro2Headline: String,
    Intro2Text: String,
    IntroLink: String,
    IntroLinkText: String,
  },
};
</script>
