<template>
  <b-container fluid class="contact-container" id="contact">
    <b-row>
      <b-col cols="0" class="col-sm-1"></b-col>
      <b-col cols="12" class="col-sm-10">
        <b-card class="contact-card kercard">
          <b-row>
            <b-col class="col-md-1 col-lg-1"></b-col>
            <b-col cols="12" class="col-md-10 col-lg-10">
              <b-row>
                <b-col>
                  <h4 class="text-center pt-5">Jetzt Kontakt aufnehmen</h4>
                </b-col>
              </b-row>
              <b-row class="pt-4">
                <b-col cols="12" class="col-md-1"></b-col>
                <b-col cols="12" class="col-md-5 mt-4 mt-md-0">
                  <p class="form-group-label">Vorname:</p>
                  <b-form-input
                    v-model="firstname"
                    @blur="validateInputFirstname(firstname)"
                    class="form-control"
                  >
                  </b-form-input>
                  <span ref="alertFirstname" class="input-false"
                    >Bitte einen Vornamen eingeben.</span
                  >
                </b-col>
                <b-col cols="12" class="col-md-5 mt-4 mt-md-0">
                  <p class="form-group-label">Nachname:</p>
                  <b-form-input
                    v-model="lastname"
                    @blur="validateInputLastname(lastname)"
                    class="form-control"
                  >
                  </b-form-input>
                  <span ref="alertLastname" class="input-false"
                    >Bitte einen Nachnamen eingeben.</span
                  >
                </b-col>
              </b-row>
              <b-row class="pt-4">
                <b-col cols="12" class="col-md-1"></b-col>
                <b-col cols="12" class="col-md-5 mt-4 mt-md-0">
                  <p class="form-group-label">E-Mail:</p>
                  <b-form-input
                    v-model="email"
                    @blur="validateInputEmail(email)"
                    class="form-control"
                  >
                  </b-form-input>
                  <span ref="alertEmail" class="input-false"
                    >Bitte eine E-Mail-Adresse eingeben.</span
                  >
                </b-col>
                <b-col cols="12" class="col-md-5 mt-4 mt-md-0">
                  <p class="form-group-label">Telefonnummer:</p>
                  <b-form-input
                    v-model="phone"
                    @blur="validateInputPhone(phone)"
                    class="form-control"
                  >
                  </b-form-input>
                  <span ref="alertPhone" class="input-false">Bitte eine Telefonnr. eingeben.</span>
                </b-col>
              </b-row>
              <b-row class="pt-4">
                <b-col cols="12" class="col-md-1"></b-col>
                <b-col cols="12" class="col-md-10 mt-4 mt-md-0">
                  <p class="form-group-label">Nachricht:</p>
                  <b-form-textarea
                    v-model="text"
                    @blur="validateInputMessage(text)"
                    class="form-control contact-textarea"
                  >
                  </b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="pt-3 contact-feedback">
                  <div class="contact-feedback-text" ref="contactfeedbacktrue">
                    Kontaktformular erfolgreich versendet.
                  </div>
                  <div class="contact-feedback-text" ref="contactfeedbackfalse">
                    Fehler beim versenden des Kontaktformulars.
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center align-items-center">
                  <b-button
                    ref="submitButton"
                    class="button-default mt-5 mb-5 justify-content-center d-flex"
                    @click="submitForm()"
                  >
                    Absenden
                    <b-spinner
                      ref="submitSpinner"
                      class="applySpinner ml-2"
                      label="Text Centered"
                    ></b-spinner>
                  </b-button>
                </b-col>
              </b-row>
              <b-col class="col-md-3 d-flex justify-content-center"></b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-lg-1 col-xl-1"></b-col>
    </b-row>
  </b-container>
</template>
<script>
/* eslint-disable */
import validator from '@/assets/js/validate';
import axios from 'axios';

export default {
  props: {
    redirectTo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      text: '',
      apiToken: '825f447d-be86-4480-9225-21c238569954',
    };
  },
  methods: {
    validateInputFirstname(input) {
      this.firstname = validator.validateInputName(input, this.$refs.alertFirstname);
    },
    validateInputLastname(input) {
      this.lastname = validator.validateInputName(input, this.$refs.alertLastname);
    },
    validateInputEmail(input) {
      this.email = validator.validateInputEmail(input, this.$refs.alertEmail);
    },
    validateInputPhone(input) {
      this.phone = validator.validateInputPhone(input, this.$refs.alertPhone);
    },
    validateInputMessage(input) {
      this.text = validator.validateInputMessage(input, this.$refs.alertMessage);
    },
    submitForm() {
      if (
        (this.firstname !== '',
        this.lastname !== '',
        this.email !== '',
        this.phone !== '',
        this.text !== '')
      ) {
        this.$refs.submitButton.disabled = true;
        this.$refs.submitSpinner.style.display = 'block';
        const formDataHost = new FormData();
        formDataHost.append(
          'recipients',
          JSON.stringify({ email: 'kontakt@keravonos.com', name: 'Keravonos Team' }),
        );
        formDataHost.append('sender', 'kontakt@keravonos.com');
        formDataHost.append('sender_name', 'keravonos.com');
        formDataHost.append('subject', 'Kontaktanfrage über keravonos.com');
        formDataHost.append('text', this.text);
        formDataHost.append(
          'html',
          `
        <h1>Kontaktformular wurde ausgefüllt von ${this.firstname} ${this.lastname}</h1>
        <p>Folgendes stand in der Kontaktanfrage: </p>
        <p>${this.text}</p>
        <p>E-Mail Adresse: ${this.email}</p>
        <p>Beste Grüße</p>
        <p>Dein Mailer Service</p>
        `,
        );
        axios
          .post('https://mail.keravonos.com/send_email', formDataHost, {
            // .post('http://127.0.0.1:5000/send_email', formDataHost, {
            headers: {
              'Api-Token': this.apiToken,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.status <= 300) {
              this.$refs.submitSpinner.style.display = 'none';
              this.$refs.contactfeedbacktrue.style.display = 'block';
              this.$refs.contactfeedbackfalse.style.display = 'none';
              if (this.redirectTo) {
                this.$router.push(this.redirectTo);
              }
            } else {
              this.$refs.contactfeedbackfalse.style.display = 'block';
              this.$refs.contactfeedbacktrue.style.display = 'none';
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>
<style scoped>
.applySpinner {
  display: none;
  color: white;
  width: 1.5rem;
  height: 1.5rem;
}
.input-false {
  display: none;
  font-size: 0.8rem;
  color: rgb(255, 0, 0);
}
.contact-container {
  background: white;
}
.contact-feedback {
  height: 2rem;
}
.contact-feedback-text {
  text-align: center;
  display: none;
}
.contact-card {
  top: -5rem;
  min-height: 25rem;
}
.contact-message {
  height: 10rem;
}
.contact-input:focus {
  border-color: #58815f;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #58815f;
  outline: 0 none;
}
.contact-formgroup {
  width: 75%;
}
.contact-textarea {
  height: 10rem;
}
.form-group {
  border: 1px solid;
  margin: 10px;
  border-radius: 0.3rem;
  border-color: #58815f;
}

.form-group-label {
  position: absolute;
  top: -1rem;
  left: 2rem;
  background-color: #f4f7f5;
  color: #58815f;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-family: 'Montserrat';
  font-size: 0.9rem;
  border-radius: 5rem;
}

.form-group > input {
  border: none;
}

.form-control:focus {
  border-color: #58815f;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #58815f;
  outline: 0 none;
}
.form-control {
  border-color: black;
}
h4 {
  font-weight: 700;
}
</style>
