<template>
 <b-container>
  <b-card no-body>
    <b-card-header header-tag="header" role="tab">
      <h3 class="mb-0">FAQ</h3>
    </b-card-header>

    <b-card-body>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <b-button v-b-toggle.q1 block variant="light">Muss mein Unternehmen die Schulung wirklich durchführen?

          </b-button>
        </b-card-header>
        <b-collapse id="q1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            Ja, alle Unternehmen, die KI nutzen, sind betroffen (EU AI Act, Art. 4).
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <b-button v-b-toggle.q2 block variant="light">Gibt es Fristen für die Umsetzung?

          </b-button>
        </b-card-header>
        <b-collapse id="q2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            Ja, ab dem 2. Februar 2025 müssen Mitarbeiter über die erforderlichen KI-Kompetenzen verfügen.
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <b-button v-b-toggle.q3 block variant="light">Was passiert, wenn meine Mitarbeiter nicht geschult sind?

          </b-button>
        </b-card-header>
        <b-collapse id="q3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            Mögliche Haftung & rechtliche Unsicherheiten bei KI-Fehlentscheidungen.
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-card-body>
  </b-card>
</b-container>

  </template>
<script>
export default {
  components: {

  },
};
</script>
<style scoped>
.jobad-icon {
  margin-right: 0.5rem;
  color: #2b572e;
}
ul {
  list-style: none;
  padding-left: 0;
}

</style>
