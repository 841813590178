<!--
JobadHeader
JobadTasks
JobaddBenefits
JobaddQuote
JobadApplication
-->
<template>
  <div class="webdev">
    <IntroComponent
      :IntroHeadline="'Werkstudent Wirtschaft'"
      :IntroBg="require('../assets/img/cup_and_keyboard_cut.png')"
      :IntroText="
        'In Zusammenarbeit mit dem Projektteam kannst du schnell Erfahrungen' +
        ' sammeln und Verantwortung für eigene Themen übernhmen. Gemeinsam entwickeln' +
        ' wir unsere Buchungssoftware weiter, automatisieren Workflows und' +
        ' implementieren beständig neue Web-Apps.'
      "
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <JobadTasks :tasks="tasks" :profiles="profiles" />
    <JobadBenefits />
    <JobadQuote
      :quoteImage="require('../assets/img/tim_huebsch.png')"
      :quoteText="
        '„Von der Testautomatisierung in Kotlin bis zur Web-APP mit Javascript und PHP.' +
        ' Trotz intensiver Sprints blieb immer noch genug Zeit, um mich ausreichend auf die Prüfungen' +
        ' vorzubereiten. Besonders in den Hackweeks wächst man als Team zusammen!“'
      "
      :quoteName="'Tim'"
      :quoteDate="'seit 2020 im Team'"
    />
    <ApplicationEcostudent />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import JobadTasks from '@/components/tools/JobadTasks.vue';
import JobadBenefits from '@/components/tools/JobadBenefits.vue';
import JobadQuote from '@/components/tools/JobadQuote.vue';
import ApplicationEcostudent from '@/components/tools/ApplicationEcostudent.vue';

export default {
  components: {
    IntroComponent,
    JobadTasks,
    JobadBenefits,
    JobadQuote,
    ApplicationEcostudent,
  },
  data() {
    return {
      tasks: [
        {
          task:
            'Du wirst hauptsächlich in Beratungsprojekten im Kontext der Digitalisierung' +
            ' unterstützen. Da wir ein junges Unternehmen im Wachstum sind, kannst du dich' +
            ' aktiv mit in die Gestaltung unserer Projekte einbringen.',
        },
      ],
      profiles: [
        {
          profile: 'Du studierst BWL, Wirtschaftsingenieurwesen oder eine ähnliche Fachrichtung',
        },
        {
          profile: 'Du möchtest erste relevante Berufserfahrung sammeln',
        },
        {
          profile: 'Idealerweise hast du schon erste Praxiserfahrung in der Projektarbeit',
        },
        {
          profile: 'Ausgeprägte IT-Affinität und Neugier für neue Technologien & Methoden',
        },
        {
          profile: 'Agiles Mindset, Empathie- & Kommunikationsfähigkeit sowie ein ausgeprägter Teamgeist',
        },
      ],
    };
  },
};
</script>
