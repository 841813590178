<template>
  <b-container class="jobad-tasks-container pb-5" fluid>
    <b-row class="pb-5">
      <b-col cols="12" class="col-md-1"></b-col>
      <b-col cols="12" class="col-md-10">
        <b-row>
          <b-col
            cols="10"
            class="col-10 offset-md-0 offset-1 col-md-6 col-lg-5 d-flex justify-content-center
            align-items-center order-md-1 order-2 justify-content-xl-center align-items-xl-center col-6 p-3"
          >
            <img src="../../assets/img/webdev_tasks.png" class="pt-5 jobad-tasks-img" alt="?" />
          </b-col>
          <b-col
            cols="12"
            class="col-md-5 col-lg-5 d-flex align-content-center flex-wrap
            justify-content-md-start order-1 order-md-2 align-items-xl-center"
          >
            <h2 class="jobad-tasks-headline mb-5 mt-5">Deine Aufgaben</h2>
            <b-list-group class="jobad-tasks-list">
              <b-list-group-item class="jobad-lists" v-for="task in tasks" :key="task.task">
                {{ task.task }}</b-list-group-item
              >
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col cols="12" class="col-md-6">
            <h2 class="jobad-tasks-headline mb-5 mt-5 d-flex justify-content-left">Dein Profil</h2>
            <b-list-group
              class="jobad-tasks-list">
              <b-list-group-item class="jobad-lists" v-for="profile in profiles" :key="profile.profile">
                {{ profile.profile }}</b-list-group-item
              >
            </b-list-group>
          </b-col>
          <b-col
            cols="10"
            class="offset-md-0 offset-1 col-md-6 col-lg-5 d-flex justify-content-center
            align-items-center order-md-1 order-2 justify-content-xl-center align-items-xl-center col-6"
          >
            <img
              src="../../assets/img/webdev_profile.png"
              class="d-flex justify-content-center jobad-tasks-img pt-4"
              alt="?"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.jobad-tasks-container {
  color: white;
  background-image: url('../../assets/img/jobadbg.png');
}
.jobad-tasks-img {
  max-width: 90%;
}
.jobad-lists {
  background-color:rgb(0,0,0,.1);
  color:white;
  margin-left:-1.2rem;
}
.jobad-tasks-headline {
  font-weight: 700;
}
.jobad-tasks-list {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
  line-height: 1.5rem;
}
</style>
<script>
export default {
  components: {},
  props: {
    tasks: Array,
    profiles: Array,
  },
};
</script>
