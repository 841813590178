<template>
  <div id="app">
    <TheHeader />
    <router-view />
    <CookieBanner />
    <TheFooter />
  </div>
</template>

<style lang="scss">
#app {
  overflow-x: hidden;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1rem;
  color: black;
}
nav {

  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import CookieBanner from '@/components/tools/CookieBanner.vue';

export default {
  components: {
    TheHeader,
    TheFooter,
    CookieBanner,
  },
};
</script>
