<!-- eslint-disable max-len -->
<template>
  <b-container fluid class="offerings-container" id="offerings">
    <b-row>
      <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>

          <b-col
            cols="12"
            class="mt-1 col-lg-12 offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3 p-5 mb-5"
          >
            <KerAICard
              :CardHeadline="'Jetzt die Schulung buchen'"
              :CardButtonText="'Kontakt aufnehmen'"
              :CardButtonLink="''"
              :CardHash="'#contact'"
              @mouseover.native="handleMouseOver('asana')"
              @mouseleave.native="handleMouseLeave"
              @focus.native="handleMouseOver('asana')"
              @blur.native="handleMouseLeave"
              :class="{ hover: hoveredCard === 'asana' }"
            />
          </b-col>
        </b-row>
  </b-container>
</template>
<script>
import KerAICard from '@/components/tools/KerAICard.vue';

export default {
  components: {
    KerAICard,
  },
  data() {
    return { hoveredCard: null };
  },
  methods: {
    handleMouseOver(cardName) {
      this.hoveredCard = cardName;
    },
    handleMouseLeave() {
      this.hoveredCard = null;
    },
  },
};
</script>
<style scoped>
.hover {
  /* background-color: #eb1a1a; Ändern Sie die Hintergrundfarbe der Karte beim Überfahren mit der Maus */
  transform: scale(1.02);
  transition: transform 0.4s ease; /* Fügen Sie eine sanfte Übergangsanimation hinzu */
}
.kercard {
  min-height: 16rem;
  transition: transform 0.4s ease; /* Fügen Sie eine sanfte Übergangsanimation hinzu */
}
.offerings-container {
  background: #d7ddd8;
}
.offerings-headline {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
}
</style>
