import Vue from 'vue';
import VueRouter from 'vue-router';
import AIContactSuccess from '../views/AIContactSuccessView.vue';
import AiTraining from '../views/AITraining.vue';
import AssView from '../views/AssView.vue';
import DatenschutzView from '../views/DatenschutzView.vue';
import EcostudentView from '../views/EcostudentView.vue';
import HomeView from '../views/HomeView.vue';
import ImpressumView from '../views/ImpressumView.vue';
import InitiativeView from '../views/InitiativeView.vue';
import ItstudentView from '../views/ItstudentView.vue';
import JobsView from '../views/JobsView.vue';
import JuconView from '../views/JuconView.vue';
import WebdevView from '../views/WebdevView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/joinoursquad',
    name: 'jobs',
    component: JobsView,
  },
  {
    path: '/webdev',
    name: 'webdev',
    component: WebdevView,
  },
  {
    path: '/ass',
    name: 'ass',
    component: AssView,
  },
  {
    path: '/jucon',
    name: 'jucon',
    component: JuconView,
  },
  {
    path: '/ecostudent',
    name: 'ecostudent',
    component: EcostudentView,
  },
  {
    path: '/itstudent',
    name: 'itstudent',
    component: ItstudentView,
  },
  {
    path: '/initiative',
    name: 'initiative',
    component: InitiativeView,
  },
  {
    path: '/KI-Schulung',
    name: 'AUtrining',
    component: AiTraining,
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenschutzView,
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView,
  },
  {
    path: '/ai-contact-success',
    name: 'AIContactSuccess',
    component: AIContactSuccess,
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 180 } };
    }
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});
export default router;
