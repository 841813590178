/* eslint-disable */
const validateInputName = (input, refInputField) => {
  const regex =
    /^$|^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  if (!regex.test(input)) {
    refInputField.style.display = 'block';
    return '';
  } else {
    refInputField.style.display = 'none';
  }
  return input;
};
const validateInputEmail = (input, refInputField) => {
  const regex = /^$|^[^\s@]+@[^\s@]+\.[^\s@./{<>}]+$/;
  if (!regex.test(input)) {
    refInputField.style.display = 'block';
    return '';
  } else {
    refInputField.style.display = 'none';
  }
  return input;
};
const validateInputPhone = (input, refInputField) => {
  const regex = /^$|^[0-9+()-]+$/;
  if (!regex.test(input)) {
    refInputField.style.display = 'block';
    return '';
  } else {
    refInputField.style.display = 'none';
  }
  return input;
};

const validateInputMessage = (input) => {
  const regex = /^$|^[\p{L}\p{N}\s.,;!?-]+$/u;
  if (!regex.test(input)) {
    input = input.replace(/[^\p{L}\p{N}\s.,;!?@#-]+/gu, ' ');
  }
  return input;
}


const validation = {
  validateInputName,
  validateInputEmail,
  validateInputPhone,
  validateInputMessage,
};

export default validation;