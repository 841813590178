<template>
  <b-container class="jobad-tasks-container pb-5" fluid>
    <b-row class="pb-5">
      <b-col cols="12" class="col-md-1"></b-col>
      <b-col cols="12" class="col-md-10">
        <b-row>
          <b-col
            cols="10"
            class="col-10 offset-md-0 offset-1 col-md-4 col-lg-4 d-flex justify-content-center
            align-items-center order-md-1 order-2 justify-content-xl-center align-items-xl-center col-6 p-3"
          >
            <img src="../../assets/img/itstudent_grundstein.png" class="pt-5 jobad-tasks-img" alt="?" />
          </b-col>
          <b-col
            cols="12"
            class="col-md-6 col-lg-6 d-flex align-content-center flex-wrap justify-content-md-start
            order-1 order-md-2 align-items-xl-center"
          >
            <h2 class="jobad-tasks-headline mb-5 mt-5">Im Studium <br/> den Grundstein legen</h2>
            <b-list-group class="jobad-tasks-list">
              <b-list-group-item class="jobad-lists">
                Wer gemeinsam Projekte bearbeitet hat, der weiß, auf wen er sich verlassen kann. Wie
                bei Soldaten, die gemeinsam im Schützengraben waren, halten solche Verbindungen
                lange und sind besonders belastbar. Bei uns kannst du die Gelegenheit nutzen, solche
                Beziehungen früh aufzubauen. Auch die Arbeit in unterscheidlichen Unternehmen
                ermöglicht gute Kontakte aufzubauen und zu vertiefen.
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col cols="12" class="col-md-6">
            <h2 class="jobad-tasks-headline mb-5 mt-5 pl-4 d-flex justify-content-left">
                Es wird niemand <br/>zurückgelassen</h2>
            <b-list-group class="jobad-tasks-list">
              <b-list-group-item class="jobad-lists ml-1">
                Die Kollegen, die schon länger dabei sind und einige Projekterfahrungen mitbringen,
                helfen dir, produktive Arbeit leisten zu können. Sie werden dir mit Rat und Tat zur
                Seite stehen, ganz nach dem Motto: „Wir entwickeln (uns) gemeinsam“.
            </b-list-group-item
              >
            </b-list-group>
          </b-col>
          <b-col
            cols="10"
            class="offset-md-0 offset-1 col-md-6 col-lg-5 d-flex justify-content-center align-items-center
            order-md-1 order-2 justify-content-xl-center align-items-xl-center col-6"
          >
            <img
              src="../../assets/img/itstudent_team.png"
              class="d-flex justify-content-center jobad-tasks-img pt-4"
              alt="?"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.jobad-tasks-container {
  color: white;
  background-image: url('../../assets/img/jobadbg.png');
}
.jobad-tasks-img {
  max-width: 90%;
}
.jobad-lists {
  background-color: rgb(0, 0, 0, 0.1);
  color: white;
  margin-left: -1.2rem;
  font-size:1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.jobad-tasks-headline {
  font-weight: 700;
  text-align: left;
}
.jobad-tasks-list {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
  line-height: 1.5rem;
}
</style>
<script>
export default {
  components: {},
  props: {
    tasks: Array,
    profiles: Array,
  },
};
</script>
