<template>
  <b-row v-show="currentStep === 1" class="pb-4 stepper-content">
    <b-col cols="12" class="col-md-1"></b-col>
    <b-col cols="12" class="pt-4 col-md-10">
      <b-row>
        <b-col cols="12" class="col-md-1"></b-col>
        <b-col cols="12" class="pt-3 col-md-10">
          <h3 class="pt-3 application-text-headline">{{ headline }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="col-md-3"></b-col>
        <b-col cols="12" class="pt-3 col-md-6 application-text">
          <b-row>
            <p>{{ text }}</p>
          </b-row>
          <b-row>
            <b-col cols="12" class="pt-3 col-md-6">
              <p class="apply-input-label">Vorname</p>
              <b-form-input
                ref="firstname"
                v-model="firstname"
                @blur="validateInputFirstname(firstname)"
                class="apply-input"
                required
              />
              <span ref="alertFirstname" class="input-false"
                    >Bitte einen Vornamen eingeben.</span
                  >
            </b-col>
            <b-col cols="12" class="pt-3 col-md-6">
              <p class="apply-input-label">Nachname</p>
              <b-form-input
                v-model="lastname"
                @blur="validateInputLastname(lastname)"
                class="apply-input"
                required
              />
              <span ref="alertLastname" class="input-false"
                    >Bitte einen Nachnamen eingeben.</span
                  >
            </b-col>
            <b-col cols="12" class="pt-3 col-md-6">
              <p class="apply-input-label">E-Mail</p>
              <b-form-input
                v-model="email"
                @blur="validateInputEmail(email)"
                class="apply-input"
                required
              />
              <span ref="alertEmail" class="input-false"
                    >Bitte eine E-Mail-Adresse eingeben.</span
                  >
            </b-col>
            <b-col cols="12" class="pt-3 col-md-6">
              <p class="apply-input-label">Telefonnummer</p>
              <b-form-input
                v-model="phone"
                @blur="validateInputPhone(phone)"
                class="apply-input"
                required
              />
              <span ref="alertPhone" class="input-false"
                    >Bitte eine Telefonnr. eingeben.</span
                  >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </b-col>
  </b-row>
</template>
<script>
import validator from '@/assets/js/validate';

export default {
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    };
  },
  props: {
    currentStep: Number,
    stepid: Number,
    headline: String,
    text: String,
  },
  methods: {
    validateInputFirstname(input) {
      this.firstname = validator.validateInputName(input, this.$refs.alertFirstname, this.$refs.firstname);
    },
    validateInputLastname(input) {
      this.lastname = validator.validateInputName(input, this.$refs.alertLastname);
    },
    validateInputEmail(input) {
      this.email = validator.validateInputEmail(input, this.$refs.alertEmail);
    },
    validateInputPhone(input) {
      this.phone = validator.validateInputPhone(input, this.$refs.alertPhone);
    },
    sendData() {
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
      };
      this.$emit('getValues', data);
    },
  },
};
</script>
<style scoped>
.input-false {
  color: rgb(255, 0, 0);
  display: none;
  font-size: .8rem;
  }
</style>
