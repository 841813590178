<!--
    JobsIntro
    JobList
-->
<template>
  <div class="jobs">
    <Intro2Component
      :Intro2Img="require('../assets/img/office_2.png')"
      :Intro2Headline="'Werde Teil des Teams'"
      :Intro2Text="`Keravonos, das ist ein junges Team mit einer Leidenschaft für Softwareentwicklung
und Digitalisierung. Wir kreieren kundenorientierte Web-Lösungen, optimieren
digitale Prozesse und erstellen innovative Konzepte.`"
    />
    <JobList />
  </div>
</template>
<script>
import Intro2Component from '@/components/tools/Intro2Component.vue';
import JobList from '@/components/tools/JobList.vue';

export default {
  components: {
    Intro2Component,
    JobList,
  },
};
</script>
<style></style>
